import "./style.css";
import logo from "./logo.svg";

import { Context } from "../Context";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { Voice } from "../Voice";
import homea from "./img/homea.svg";
import sales from "./img/sales.svg";
import marketing from "./img/marketing.svg";
import pages from "./img/pages.svg";
import analis from "./img/analitics.svg";
import settings from "./img/settings.svg";
import exit from "./img/exit.svg";
import arr from "./img/arr.svg";
import marketinga from "./img/marketinga.svg";
import salesa from "./img/salesa.svg";
import products from "./img/products.svg";
import productsa from "./img/productsa.svg";
import analisa from "./img/analiticsa.svg";
import home from "./img/home.svg";
import i1 from "./img/1.svg";
import i2 from "./img/2.svg";
import i3 from "./img/3.svg";
import i4 from "./img/4.svg";
import i5 from "./img/5.svg";
import i6 from "./img/6.svg";
import { Fade } from "react-reveal";
export default function Navbar() {
  const [voice, setVoice] = useContext(Voice);
  const [inputVoice, setInputVoice] = useState("");
  const [outputVoice, setOutputVoice] = useState("");
  const [settingss, openSettings] = useState(false);
  const Navigate = useNavigate();
  const [openPages, isOpenpages] = useState(false);
  const location = useLocation();
  return (
    <div className="navbar">
      <img
        src={logo}
        style={{ cursor: "pointer" }}
        alt=""
        onClick={() => Navigate(routes.main)}
      />
      <span className="menuElemsNav">
        <div className="home" onClick={() => Navigate(routes.main)}>
          <img src={location.pathname == routes.main ? homea : home} alt="" />
          <p>Главная</p>
        </div>
        <div
          className="home"
          style={
            openPages ? { background: "#E7ECFD", borderRadius: "10px" } : {}
          }
          onClick={() => isOpenpages(!openPages)}
        >
          <img src={pages} alt="" />
          <p>Страницы</p>
          <img
            src={arr}
            className="arr"
            alt=""
            style={openPages ? { rotate: "180deg" } : {}}
          />
        </div>
        {openPages && (
          <Fade left duration={500}>
            {" "}
            <div className="pagesUl">
              <ul>
                <li
                  onClick={() =>
                    Navigate(
                      routes.edit + "?key=https://веб-витрина.рф/services"
                    )
                  }
                >
                  <img src={i1} alt="" />
                  <p>Каталог</p>
                </li>
                <li>
                  <img src={i2} alt="" />
                  <p>Корзина</p>
                </li>
                <li>
                  <img src={i3} alt="" />
                  <p>О компании</p>
                </li>
                <li>
                  <img src={i4} alt="" />
                  <p>Контакты</p>
                </li>
                <li>
                  <img src={i5} alt="" />
                  <p>Документы</p>
                </li>
                <li>
                  <img src={i6} alt="" />
                  <p>On-line Чат</p>
                </li>
              </ul>
            </div>
          </Fade>
        )}
        <div className="home" onClick={() => Navigate(routes.orders)}>
          <img
            src={location.pathname == routes.orders ? salesa : sales}
            alt=""
          />
          <p>Заявки</p>
        </div>
        <div className="home" onClick={() => Navigate(routes.tovar)}>
          <img
            src={location.pathname == routes.tovar ? productsa : products}
            alt=""
          />
          <p>Товары</p>
        </div>
        <div className="home" onClick={() => Navigate(routes.main)}>
          <img src={analis} alt="" />
          <p>Аналитика</p>
        </div>
        <div className="home" onClick={() => Navigate(routes.main)}>
          <div style={{ width: "40px" }} className="lordI">
            <img src={marketing} alt="" />
          </div>
          <p>Маркетинг</p>
        </div>
        <div className="home" onClick={() => openSettings(!settingss)}>
          <img src={settings} alt="" />
          <p>Настройки</p>
        </div>
        {settingss && (
          <Fade left duration={500}>
            <div className="pagesUl">
              <ul>
                <li onClick={() => Navigate(routes.themes)}>
                  <img src={i1} alt="" />
                  <p>Темы</p>
                </li>
                <li onClick={() => Navigate(routes.profile)}>
                  <img src={i2} alt="" />
                  <p>Профиль</p>
                </li>
              </ul>
            </div>
          </Fade>
        )}
      </span>
      <div className="home exist" onClick={() => Navigate(routes.main)}>
        <img src={exit} alt="" />
        <p>Свернуть</p>
      </div>
    </div>
  );
}
